import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, element: Element, isAdminRequired, isAdmin }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (isAdminRequired && !isAdmin) {
    return <Navigate to="/profile" />;
  }

  return <Element />;
};

export default PrivateRoute;
