// Base URL for your backend API
const API_BASE_URL = "https://api.1300sitecam.com.au:5001"; 
const API_BASE_URL2 = "https://api.1300sitecam.com.au:5002"; 
// Function to sign in a user
export const signIn = async (username, password) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to sign in');
    }

    const data = await response.json();
    
    // Save tokens and user data to sessionStorage
    sessionStorage.setItem("idToken", data.idToken || '');
    sessionStorage.setItem("accessToken", data.accessToken || '');
    sessionStorage.setItem("refreshToken", data.refreshToken || '');
    sessionStorage.setItem("userId", username); 
    sessionStorage.setItem("isAdmin", data.isAdmin);
    sessionStorage.setItem("folders", JSON.stringify(data.folders || []));

    return data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};

// Function to sign up a user
export const signUp = async (email, password) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to sign up');
    }

    return await response.json();
  } catch (error) {
    console.error('Error signing up:', error);
    throw error;
  }
};

// Function to confirm sign-up
export const confirmSignUp = async (username, code) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/confirm-signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, code }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to confirm sign up');
    }

    return true;
  } catch (error) {
    console.error('Error confirming sign up:', error);
    throw error;
  }
};

// Function to change password
// Function to change password
export const changePassword = async (accessToken, previousPassword, proposedPassword) => {
  try {
    const response = await fetch(`${API_BASE_URL2}/auth/change-password`, { // Use the correct base URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,  // Ensure the access token is passed correctly
      },
      body: JSON.stringify({
        accessToken,           // Include accessToken in the body for backend validation
        previousPassword,      // The user's current password
        proposedPassword       // The new password the user wants to set
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to change password');
    }

    return await response.json();
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};



// Function to request password reset code
export const requestPasswordResetCode = async (username) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/request-reset-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to request password reset code');
    }

    return true;
  } catch (error) {
    console.error('Error requesting password reset code:', error);
    throw error;
  }
};

// Function to reset password
export const resetPassword = async (username, code, newPassword) => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, code, newPassword }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to reset password');
    }

    return true;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

// Function to retrieve user folders from the backend
export const getUserFolders = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/get-user-folders`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("idToken")}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to retrieve user folders');
    }

    const data = await response.json();
    sessionStorage.setItem("folders", JSON.stringify(data.folders || []));
    return data.folders;
  } catch (error) {
    console.error('Error retrieving user folders:', error);
    throw error;
  }
};
