// src/ReportPage.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  Stack,
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs'; // For date manipulation

function ReportPage() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filterType, setFilterType] = useState('offline'); // Default filter type is 'offline'
  const [showAllInfo, setShowAllInfo] = useState(false);
  const [reportView, setReportView] = useState(false); // New state to control report view

  // New state to indicate data fetching on page load
  const [initialLoading, setInitialLoading] = useState(true);

  // Function to sort devices
  const sortDevices = (devicesArray) => {
    return devicesArray.sort((a, b) => {
      if (a.DeviceStatus === b.DeviceStatus) {
        return a.State.localeCompare(b.State);
      } else if (a.DeviceStatus === 'Offline') {
        return -1;
      } else if (b.DeviceStatus === 'Offline') {
        return 1;
      } else if (a.DeviceStatus === 'Unknown') {
        return -1;
      } else if (b.DeviceStatus === 'Unknown') {
        return 1;
      } else {
        return 0;
      }
    });
  };

  // Function to group devices by State
  const groupDevicesByState = (devices) => {
    return devices.reduce((acc, device) => {
      const state = device.State || 'Unknown';
      if (!acc[state]) {
        acc[state] = [];
      }
      acc[state].push(device);
      return acc;
    }, {});
  };

  // Fetch data from backend on component mount
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get('https://api.1300sitecam.com.au:5005/api/getDevices');
        const { data } = response;

        if (data.devices) {
          // Filter devices where OnHire is true
          const onHireDevices = data.devices.filter((device) => device.OnHire === true);

          // Sort devices
          const sortedDevices = sortDevices(onHireDevices);

          setDevices(sortedDevices);

          // By default, show only offline devices
          const offlineDevices = sortedDevices.filter((device) => device.DeviceStatus === 'Offline');
          setFilteredDevices(offlineDevices);
          setFilterType('offline');

          setNotification({ open: true, message: 'Devices loaded successfully.', severity: 'success' });
        } else {
          setNotification({ open: true, message: 'No devices found in the response.', severity: 'warning' });
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
        setNotification({ open: true, message: 'Error fetching devices: ' + error.message, severity: 'error' });
      } finally {
        setInitialLoading(false);
      }
    };

    fetchDevices();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setNotification({ open: true, message: 'Please select a file to upload.', severity: 'warning' });
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post('https://api.1300sitecam.com.au:5005/api/uploadExcel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { data } = response;

      if (data.devices) {
        // Sort devices by DeviceStatus (Offline first), then by State
        const sortedDevices = sortDevices(data.devices);

        setDevices(sortedDevices);

        // Apply the same default filter after upload
        const offlineDevices = sortedDevices.filter((device) => device.DeviceStatus === 'Offline');
        setFilteredDevices(offlineDevices);
        setFilterType('offline');

        setNotification({ open: true, message: 'File uploaded and data received successfully.', severity: 'success' });
      } else {
        setNotification({ open: true, message: 'No devices found in the response.', severity: 'warning' });
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setNotification({ open: true, message: 'Error uploading file: ' + error.message, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const filterOfflineDevices = () => {
    const offlineDevices = devices.filter((device) => device.DeviceStatus === 'Offline');
    setFilteredDevices(offlineDevices);
    setFilterType('offline');
  };

  const filterNewOfflineDevices = () => {
    const twelveHoursAgo = dayjs().subtract(12, 'hour');
    const filtered = devices.filter((device) => {
      const isOffline = device.DeviceStatus === 'Offline';
      const isNewOffline =
        device.offlinecount === 1 ||
        (device.offlinetime && dayjs(device.offlinetime).isAfter(twelveHoursAgo));
      return isOffline && isNewOffline;
    });
    const sorted = filtered.sort((a, b) => a.State.localeCompare(b.State));
    setFilteredDevices(sorted);
    setFilterType('newOffline');
  };

  const filterAgedOfflineDevices = () => {
    const twelveHoursAgo = dayjs().subtract(12, 'hour');
    const filtered = devices.filter(
      (device) =>
        device.DeviceStatus === 'Offline' &&
        device.offlinecount > 1 &&
        device.offlinetime &&
        dayjs(device.offlinetime).isBefore(twelveHoursAgo)
    );
    const sorted = filtered.sort((a, b) => a.State.localeCompare(b.State));
    setFilteredDevices(sorted);
    setFilterType('agedOffline');
  };

  const showAllDevices = () => {
    setFilteredDevices(devices);
    setFilterType('all');
  };

  const toggleShowAllInfo = () => {
    setShowAllInfo((prev) => !prev);
  };

  const toggleReportView = () => {
    setReportView((prev) => !prev);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Device Report
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Input type="file" onChange={handleFileChange} />
        <Button variant="contained" color="primary" onClick={handleUpload} sx={{ ml: 2 }}>
          Upload
        </Button>
      </Box>

      {(loading || initialLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {devices.length > 0 && !loading && !initialLoading && (
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Button
              variant={filterType === 'offline' ? 'contained' : 'outlined'}
              onClick={filterOfflineDevices}
            >
              Offline Devices
            </Button>
            <Button
              variant={filterType === 'all' ? 'contained' : 'outlined'}
              onClick={showAllDevices}
            >
              Show All Devices
            </Button>
            <Button
              variant={filterType === 'newOffline' ? 'contained' : 'outlined'}
              onClick={filterNewOfflineDevices}
            >
              New Offline Devices
            </Button>
            <Button
              variant={filterType === 'agedOffline' ? 'contained' : 'outlined'}
              onClick={filterAgedOfflineDevices}
            >
              Aged Offline Devices
            </Button>
            <Button
              variant="outlined"
              onClick={toggleReportView}
            >
              {reportView ? 'Table View' : 'Report View'}
            </Button>
            {!reportView && (
              <Button variant="outlined" onClick={toggleShowAllInfo}>
                {showAllInfo ? 'Show Less Info' : 'Show All Info'}
              </Button>
            )}
          </Stack>

          {reportView ? (
            // Report View Rendering
            <Box>
              {filteredDevices.length > 0 ? (
                Object.entries(groupDevicesByState(filteredDevices)).map(([state, devices]) => (
                  <Box key={state} sx={{ mb: 4 }}>
                    <Typography variant="h6">{state}</Typography>
                    {devices.map((device, index) => {
                      const addressLine2 = device.AddressLine2 ? `, ${device.AddressLine2}` : '';
                      const deviceInfo = `${device.Serial} - ${device.Customer || 'N/A'} - ${
                        device.JobNo
                      }, ${device.AddressLine1}${addressLine2}, ${device.Suburb}, ${device.State}`;
                      return (
                        <Typography key={index} sx={{ ml: 2 }}>
                          {deviceInfo}
                        </Typography>
                      );
                    })}
                  </Box>
                ))
              ) : (
                <Typography>No devices to display.</Typography>
              )}
            </Box>
          ) : (
            // Table View Rendering
            <TableContainer component={Paper}>
              <Table aria-label="device table">
                <TableHead>
                  <TableRow>
                    <TableCell>Serial</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>JobNo</TableCell>
                    <TableCell>Device Status</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Suburb</TableCell>
                    <TableCell>AddressLine1</TableCell>
                    <TableCell>AddressLine2</TableCell>
                    <TableCell>Offline Time</TableCell>
                    <TableCell>Offline Count</TableCell>
                    <TableCell>Longitude & Latitude</TableCell>
                    {showAllInfo &&
                      Object.keys(devices[0])
                        .filter(
                          (key) =>
                            ![
                              'Serial',
                              'Customer',
                              'JobNo',
                              'DeviceStatus',
                              'State',
                              'Suburb',
                              'AddressLine1',
                              'AddressLine2',
                              'Longitude',
                              'Latitude',
                              'offlinetime',
                              'offlinecount',
                            ].includes(key)
                        )
                        .map((key) => (
                          <TableCell key={key}>{key}</TableCell>
                        ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredDevices.map((device, index) => (
                    <TableRow key={index}>
                      <TableCell>{device.Serial}</TableCell>
                      <TableCell>{device.Customer || 'N/A'}</TableCell>
                      <TableCell>{device.JobNo}</TableCell>
                      <TableCell>{device.DeviceStatus}</TableCell>
                      <TableCell>{device.State}</TableCell>
                      <TableCell>{device.Suburb}</TableCell>
                      <TableCell>{device.AddressLine1}</TableCell>
                      <TableCell>{device.AddressLine2 || 'N/A'}</TableCell>
                      <TableCell>
                        {device.offlinetime
                          ? dayjs(device.offlinetime).format('YYYY-MM-DD HH:mm:ss')
                          : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {device.offlinecount !== undefined ? device.offlinecount : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {device.Longitude && device.Latitude ? (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${device.Latitude},${device.Longitude}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {device.Longitude}, {device.Latitude}
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      {showAllInfo &&
                        Object.keys(device)
                          .filter(
                            (key) =>
                              ![
                                'Serial',
                                'Customer',
                                'JobNo',
                                'DeviceStatus',
                                'State',
                                'Suburb',
                                'AddressLine1',
                                'AddressLine2',
                                'Longitude',
                                'Latitude',
                                'offlinetime',
                                'offlinecount',
                              ].includes(key)
                          )
                          .map((key) => (
                            <TableCell key={key}>
                              {device[key] !== null && device[key] !== undefined ? device[key] : 'N/A'}
                            </TableCell>
                          ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          onClose={() => setNotification({ ...notification, open: false })}
          severity={notification.severity}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ReportPage;
